<template>
    <section :class="props.bgFill ? 'bg-gray-100' : ''">
        <div class="py-10 sm:py-28">
            <div class="container m-auto">
                <Reviews />
            </div>
        </div>
    </section>
</template>

<script setup lang="ts">
    import Reviews from '~/components/Review/Reviews.vue'
    const props = defineProps<{
        bgFill: boolean
    }>()
</script>
