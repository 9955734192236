<template>
    <div class="flex flex-wrap items-center justify-center gap-4 p-8 text-white sm:flex-nowrap md:justify-between md:gap-8">
        <section class="flex grow flex-col gap-4">
            <span class="font-extrabold">
                {{ $t('promotionalWheel.spinTheWheel') }}
            </span>
            <i18n-t keypath="promotionalWheel.foodticketRaffle" tag="span" scope="global">
                <template #grandPrize>
                    <span class="font-bold">{{ $t('promotionalWheel.grandPrize') }}</span>
                </template>
            </i18n-t>
        </section>
        <div class="relative flex w-3/5 md:w-auto">
            <div>
                <img src="/img/promotions/prizewheelBoard.png" class="z-10 animate-[spin_40s_infinite_forwards_linear]" />
                <img src="/img/promotions/starflare.svg?url" class="absolute top-0 z-20 animate-[spin_40s_infinite_reverse_forwards_linear]" />
            </div>
            <img src="/img/promotions/prizewheelToyotaAygo.png" alt="Toyota Aygo prize" class="absolute bottom-0 left-0 z-30 -translate-x-14 sm:-translate-x-5" />
        </div>
    </div>
</template>
