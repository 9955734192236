<template>
    <section :class="props.bgFill ? 'bg-gray-100' : ''">
        <div class="py-10 sm:py-28">
            <div class="container m-auto">
                <div class="flex flex-col justify-center gap-6 md:flex-row md:gap-12 lg:gap-20">
                    <div class="flex w-full flex-col md:w-1/3">
                        <div class="mb-4 flex items-center justify-center gap-2 text-xl font-bold capitalize leading-none">
                            <Icon :icon="IconNavigationArrow" :auto-align="false" /> {{ $t('location') }}
                        </div>
                        <div class="flex flex-col gap-4">
                            <div class="flex flex-col items-center gap-2 md:gap-4">
                                <span class="font-bold">{{ webshopSettings()?.global?.client?.company }} <br /></span>
                                <address class="not-italic">
                                    <ul class="flex flex-col gap-2 text-center">
                                        <li>
                                            <a
                                                :href="`https://www.google.com/maps/search/?api=1&query=${webshopSettings()?.global?.client?.address}+${webshopSettings()?.global?.client?.postalCode}+${webshopSettings()?.global?.client?.city}`"
                                                target="_blank"
                                                class="text-center text-blue-950"
                                            >
                                                {{ webshopSettings()?.global?.client?.address }} <br />
                                                {{ webshopSettings()?.global?.client?.postalCode }} {{ webshopSettings()?.global?.client?.city }}
                                            </a>
                                        </li>
                                        <li v-if="webshopSettings()?.global?.client?.tel">
                                            <span>{{ $t('telephoneAbbreviated') }} {{ webshopSettings()?.global?.client?.tel }}</span>
                                        </li>
                                    </ul>
                                </address>
                            </div>
                            <div
                                v-if="webshopSettings()?.global?.mobileApps?.ios || webshopSettings()?.global?.mobileApps?.android"
                                class="mt-4 flex justify-center gap-4"
                            >
                                <a
                                    v-if="webshopSettings()?.global?.mobileApps?.ios"
                                    :href="webshopSettings()?.global?.mobileApps?.ios"
                                    target="_blank"
                                >
                                    <img
                                        src="/svg/apple-download.svg?url"
                                        class="w-28"
                                        height="140"
                                    />
                                </a>
                                <a
                                    v-if="webshopSettings()?.global?.mobileApps?.ios"
                                    :href="webshopSettings()?.global?.mobileApps?.android"
                                    target="_blank"
                                >
                                    <img
                                        src="/svg/googleplay.svg?url"
                                        class="w-28"
                                        height="140"
                                    />
                                </a>
                            </div>
                        </div>
                        <div
                            v-if="webshopSettings().global.socialMedia?.facebook?.url || webshopSettings().global.socialMedia?.instagram?.url || webshopSettings().global.socialMedia?.twitter?.url"
                            class="mt-4 flex justify-center gap-4"
                        >
                            <a
                                v-if="webshopSettings().global.socialMedia?.facebook?.url"
                                :href="webshopSettings().global.socialMedia?.facebook?.url"
                                target="_blank"
                                rel="noopener noreferrer"
                                class="text-2xl"
                            >
                                <Icon :icon="IconFacebook" />
                            </a>
                            <a
                                v-if="webshopSettings().global.socialMedia?.instagram?.url"
                                :href="webshopSettings().global.socialMedia?.instagram?.url"
                                target="_blank"
                                rel="noopener noreferrer"
                                class="text-2xl"
                            >
                                <Icon :icon="IconInstagram" />
                            </a>
                            <a
                                v-if="webshopSettings().global.socialMedia?.twitter?.url"
                                :href="webshopSettings().global.socialMedia?.twitter?.url"
                                target="_blank"
                                rel="noopener noreferrer"
                                class="text-2xl"
                            >
                                <Icon :icon="IconTwitter" />
                            </a>
                        </div>
                    </div>
                    <div v-if="webshopSettings().deliveryIsEnabled && webshopSettings().deliveryHours.length > 0" class="flex w-full flex-col md:w-1/3">
                        <div class="mb-4 flex items-center justify-center gap-2 text-xl font-bold capitalize leading-none">
                            <Icon :icon="IconBike" /> {{ $t('deliveryTimes') }}
                        </div>
                        <div class="flex flex-col">
                            <table class="self-center whitespace-nowrap">
                                <tbody>
                                    <tr v-for="time in webshopSettings().deliveryHours" :key="`${time!.dayName}-delivery`">
                                        <td class="w-28 lowercase first-letter:uppercase">
                                            {{ $t(`weekdays.${time.dayName.toLowerCase()}`) }}
                                        </td>
                                        <td v-if="time!.isOpen" class="w-32 text-right tabular-nums">
                                            <div v-for="range in time!.openingHoursRange" :key="`${time!.dayName}-delivery-${range?.start}`">
                                                <span>
                                                    {{ range?.start }} -
                                                </span>
                                                <span>
                                                    {{ range?.end }}
                                                </span>
                                            </div>
                                        </td>
                                        <td v-else class="w-32 text-right tabular-nums">
                                            <ToolTip v-if="time?.reasonClosed">
                                                <div class="underline decoration-dotted">
                                                    {{ $t('closed') }}
                                                </div>
                                                <template #popper>
                                                    <div>
                                                        {{ time?.reasonClosed }}
                                                    </div>
                                                </template>
                                            </ToolTip>
                                            <div v-else>
                                                {{ $t('closed') }}
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div v-if="webshopSettings().pickupIsEnabled && webshopSettings().pickupHours.length > 0" class="flex w-full flex-col md:w-1/3">
                        <div class="mb-4 flex items-center justify-center gap-2 text-xl font-bold capitalize leading-none">
                            <Icon :icon="IconShoppingStore" /> {{ $t('pickupTimes') }}
                        </div>
                        <div class="flex flex-col">
                            <table class="self-center whitespace-nowrap">
                                <tbody>
                                    <tr v-for="time in webshopSettings().pickupHours" :key="`${time!.dayName}-pickup`">
                                        <td class="w-28 align-text-top lowercase first-letter:uppercase">
                                            {{ $t(`weekdays.${time.dayName.toLowerCase()}`) }}
                                        </td>
                                        <td v-if="time!.isOpen" class="w-32 text-right tabular-nums">
                                            <div v-for="range in time!.openingHoursRange" :key="`${time!.dayName}-pickup-${range?.start}`">
                                                <span>
                                                    {{ range?.start }} -
                                                </span>
                                                <span>
                                                    {{ range?.end }}
                                                </span>
                                            </div>
                                        </td>
                                        <td v-else class="w-32 text-right tabular-nums">
                                            <ToolTip v-if="time?.reasonClosed">
                                                <div class="underline decoration-dotted">
                                                    {{ $t('closed') }}
                                                </div>
                                                <template #popper>
                                                    <div>
                                                        {{ time?.reasonClosed }}
                                                    </div>
                                                </template>
                                            </ToolTip>
                                            <div v-else>
                                                {{ $t('closed') }}
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script setup lang="ts">
    import { webshopSettings } from '~/store/webshopSettings'
    import Icon from '~/components/Icon.vue'
    import IconNavigationArrow from '@/assets/icons/navigation-arrow.svg'
    import IconBike from '@/assets/icons/bike.svg'
    import IconShoppingStore from '@/assets/icons/shopping-store.svg'
    import IconFacebook from '@/assets/icons/facebook.svg'
    import IconInstagram from '@/assets/icons/instagram.svg'
    import IconTwitter from '@/assets/icons/twitter.svg'
    import ToolTip from '~/components/ToolTip.vue'
    const props = defineProps<{
        bgFill: boolean
    }>()
</script>
