<template>
    <section>
        <div class="py-10 sm:py-28">
            <div class="container m-auto">
                <div class="flex flex-col items-center justify-center gap-6 text-center md:flex-row md:items-start md:gap-12 lg:gap-20">
                    <div class="flex w-full flex-col md:w-1/3">
                        <div class="mb-4 flex items-center justify-center gap-2 text-xl font-bold capitalize leading-none">
                            <Icon :icon="IconClock" /> {{ $t('today') }}!
                        </div>
                        <div class="flex flex-col">
                            <div v-if="webshopSettings()?.global?.emergencyBrake?.closedTemporarily">
                                {{ $t('temporarilyClosed') }}
                            </div>
                            <div v-else class="flex flex-col gap-3">
                                <div v-if="webshopSettings().deliveryIsEnabled && deliveryHours?.length > 0">
                                    <ul v-for="item in deliveryHours" :key="`${item!.dayName}-delivery`" class="flex flex-col">
                                        <li class="font-bold">
                                            {{ $t('deliveryFrom') }}:
                                        </li>
                                        <template
                                            v-if="item?.isOpen"
                                        >
                                            <li
                                                v-for="range in item?.openingHoursRange"
                                                :key="`${item!.dayName}-delivery-${range?.start}`"
                                            >
                                                <span>
                                                    {{ range?.start }} -
                                                </span>
                                                <span>
                                                    {{ range?.end }}
                                                </span>
                                            </li>
                                        </template>
                                        <div
                                            v-else
                                        >
                                            <ToolTip v-if="item?.reasonClosed">
                                                <div class="underline decoration-dotted">
                                                    {{ $t('closed') }}
                                                </div>
                                                <template #popper>
                                                    <div>
                                                        {{ item?.reasonClosed }}
                                                    </div>
                                                </template>
                                            </ToolTip>
                                            <div v-else>
                                                {{ $t('closed') }}
                                            </div>
                                        </div>
                                    </ul>
                                </div>
                                <div v-if="webshopSettings().pickupIsEnabled && pickupHours?.length > 0">
                                    <template v-if="!webshopSettings()?.global?.emergencyBrake?.pickupClosedTemporarily">
                                        <ul v-for="item in pickupHours" :key="`${item!.dayName}-pickup`" class="flex flex-col">
                                            <li class="font-bold">
                                                {{ $t('pickupFrom') }}:
                                            </li>
                                            <template
                                                v-if="item?.isOpen"
                                            >
                                                <li v-for="range in item?.openingHoursRange" :key="`${item!.dayName}-pickup-${range?.start}`">
                                                    <span>
                                                        {{ range?.start }} -
                                                    </span>
                                                    <span>
                                                        {{ range?.end }}
                                                    </span>
                                                </li>
                                            </template>
                                            <div
                                                v-else
                                            >
                                                <ToolTip v-if="item?.reasonClosed">
                                                    <div class="underline decoration-dotted">
                                                        {{ $t('closed') }}
                                                    </div>
                                                    <template #popper>
                                                        <div>
                                                            {{ item?.reasonClosed }}
                                                        </div>
                                                    </template>
                                                </ToolTip>
                                                <div v-else>
                                                    {{ $t('closed') }}
                                                </div>
                                            </div>
                                        </ul>
                                    </template>
                                    <div v-else>
                                        {{ $t('temporarilyClosedForPickup') }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="flex w-full flex-col md:w-1/3">
                        <div class="mb-4 flex items-center justify-center gap-2 text-xl font-bold leading-none">
                            <Icon :icon="IconEuroCircle" :auto-align="false" />
                            {{ $t('deliveryCosts') }}
                            <template v-if="!hasMounted || !hasPostalCode()">
                                (indicatief)
                            </template><template v-else>
                                ({{ checkout().input.customer.addressGroup.postalCode.toUpperCase() }})
                            </template>
                        </div>
                        <transition name="fade" mode="out-in">
                            <template v-if="!hasMounted || !hasPostalCode()">
                                <ul class="flex flex-col">
                                    <li>{{ $t('deliveryCostDefault') }} {{ currencyFormat(webshopSettings()?.global?.delivery?.deliveryCosts) }}</li>
                                    <li>{{ $t('deliveryCostMinimal') }} {{ currencyFormat(webshopSettings()?.global?.delivery?.minimumOrderThreshold) }}</li>
                                    <li>{{ $t('deliveryFreeFrom') }} {{ currencyFormat(webshopSettings()?.global?.delivery?.freeDeliveryThreshold) }}</li>
                                </ul>
                            </template>
                            <template v-else>
                                <ul v-if="isPostalCodeInRange()" class="flex flex-col">
                                    <li>{{ $t('deliveryCostDefault') }} {{ currencyFormat(webshopSettings().getCurrentDeliveryArea?.deliveryCosts) }}</li>
                                    <li>{{ $t('deliveryCostMinimal') }} {{ currencyFormat(webshopSettings().getCurrentDeliveryArea?.minimumOrderThreshold) }}</li>
                                    <li>{{ $t('deliveryFreeFrom') }} {{ currencyFormat(webshopSettings().getCurrentDeliveryArea?.freeDeliveryThreshold) }}</li>
                                </ul>
                                <div v-else>
                                    {{ $t('shop.cart.limitations.noDeliveryOnPostCode') }}
                                </div>
                            </template>
                        </transition>
                    </div>
                    <div class="flex w-full flex-col md:w-1/3">
                        <div class="mb-4 flex items-center justify-center gap-2 text-xl font-bold capitalize leading-none">
                            <Icon :icon="IconNavigationArrow" :auto-align="false" /> {{ $t('location') }}
                        </div>
                        <div class="flex flex-col">
                            <address class="not-italic">
                                <ul class="flex flex-col gap-2 text-center">
                                    <li>
                                        <a
                                            :href="`https://www.google.com/maps/search/?api=1&query=${webshopSettings()?.global?.client?.address}+${webshopSettings()?.global?.client?.postalCode}+${webshopSettings()?.global?.client?.city}`"
                                            target="_blank"
                                            class="text-center text-blue-950"
                                        >
                                            {{ webshopSettings()?.global?.client?.address }} <br />
                                            {{ webshopSettings()?.global?.client?.postalCode }} {{ webshopSettings()?.global?.client?.city }}
                                        </a>
                                    </li>
                                    <li v-if="webshopSettings()?.global?.client?.tel">
                                        <span>{{ $t('telephoneAbbreviated') }} {{ webshopSettings()?.global?.client?.tel }}</span>
                                    </li>
                                </ul>
                            </address>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script setup lang="ts">
    import { checkout } from '~/store/checkout'
    import { webshopSettings } from '~/store/webshopSettings'
    import type { ClientOpenOrderBuddy } from '~/types/orderbuddy-types'
    import { currencyFormat } from '@/utils/currency-utils'
    import Icon from '~/components/Icon.vue'
    import IconEuroCircle from '@/assets/icons/euro-circle.svg'
    import IconNavigationArrow from '@/assets/icons/navigation-arrow.svg'
    import IconClock from '@/assets/icons/clock.svg'
    import { DispatchTypeOrderBuddy } from '~/types/orderbuddy-types'
    import ToolTip from '~/components/ToolTip.vue'
    const currentDay = new Date().getDay()
    const deliveryHours = webshopSettings()?.global?.openingHours?.filter((item: ClientOpenOrderBuddy) => item.dayNumber === currentDay && item.dispatchType === DispatchTypeOrderBuddy.DELIVER)
    const pickupHours = webshopSettings()?.global?.openingHours?.filter((item: ClientOpenOrderBuddy) => item.dayNumber === currentDay && item.dispatchType === DispatchTypeOrderBuddy.PICK_UP)
    // TODO: The hasMounted mechanism is needed because somehow, the postal code from the localStorage from the Pinia stores is set before the client side hydration is done, resulting into hydration mismatches
    // TODO: It is not clear what is the issue - it is either the @pinia-plugin-persistedstate/nuxt or @formkit/nuxt.
    // TODO: We should recheck when https://foodticket.atlassian.net/browse/WSV3-40 is being done if perhaps a new formkit version fixes this issue
    // TODO: We could also try to make a custom localStorage implementation in Pinia using e.g. https://pinia.vuejs.org/cookbook/composables.html#SSR to see if this issue. It should also be checked if manually restoring localStorage onMounted resolves the issue.
    // TODO: Check if the https://nuxt.com/docs/guide/directory-structure/components#paired-with-a-client-component approach works (it may be cleaner and an easier pattern to repeat in other places)
    const hasMounted = ref(false)
    onMounted(async () => {
        hasMounted.value = true
    })
</script>
