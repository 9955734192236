<template>
    <div class="relative w-full overflow-hidden bg-gray-100">
        <div class="container mx-auto flex py-10 sm:py-20">
            <section class="relative z-20 flex w-full flex-col space-y-4 md:w-8/12 lg:w-6/12 xl:w-5/12">
                <div class="flex w-4/6 flex-col gap-4 sm:w-8/12 md:w-full">
                    <h1 class="text-2xl font-bold leading-none text-black md:text-4xl lg:text-5xl">
                        {{ webshopSettings()?.translated?.hero?.title || `${$t('welcomeTo')} ${webshopSettings()?.global?.client?.company}` }}
                    </h1>
                    <span class="md:text-xl" v-html="webshopSettings()?.translated?.hero?.subtitle || $t('subtitle')" />
                    <div v-if="reviews().hasReviews" class="flex items-center gap-2">
                        <StarRating :rating="reviews()?.reviewStats?.averageRating" />
                        <span class="font-bold">{{ reviews()?.reviewStats?.averageRating! }}</span>
                        <div class="flex flex-auto gap-0">
                            (<nuxt-link :to="localePath('/reviews/1')" class="underline transition-opacity hover:opacity-70">
                                {{ reviews()?.reviewStats?.totalReviews }} {{ reviews()?.reviewStats?.totalReviews! > 1 ? $t('review.reviews') : $t('review.review') }}
                            </nuxt-link>)
                        </div>
                    </div>
                </div>
                <HeroTabs />
            </section>
            <div
                class="absolute bottom-0 z-10 aspect-square overflow-hidden md:left-2/3 md:right-0 md:top-0 md:h-full lg:left-1/2"
                :class="
                    webshopSettings()?.translated?.hero?.title && webshopSettings()?.translated?.hero?.subtitle
                        ? '-right-12 -top-6 w-36 sm:-right-24 md:right-0 sm:-top-24 sm:w-52 md:w-1/2'
                        : '-right-12 -top-6 w-32 sm:-right-20 md:right-0 sm:-top-20 sm:w-52 md:w-1/2'
                ">
                <NuxtImg
                    v-if="webshopSettings()?.translated?.hero?.image?.path"
                    class="relative aspect-square w-full overflow-hidden rounded-[43.75rem] object-cover object-center md:-right-1/4 md:left-8 md:top-[-5%] md:aspect-auto md:h-[110%] md:rounded-r-none md:object-left lg:-top-1/4 lg:left-16 lg:h-[150%]"
                    :src="webshopSettings()?.translated?.hero?.image?.path"
                    format="webp"
                    width="950"
                    height="950"
                    densities="x1"
                    fit="cover"
                    :placeholder="img(webshopSettings()?.translated?.hero?.image?.path, { h: 200, w: 200, fit: 'cover' })"
                    alt="shop hero image" />
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
    import { webshopSettings } from '~/store/webshopSettings'
    import { reviews } from '~/store/reviews'
    import HeroTabs from '~/components/Hero/HeroTabs.vue'
    import StarRating from '~/components/StarRating.vue'
    const localePath = useLocalePath()
    const img = useImage() // TODO: use srcset
</script>

<style lang="scss">
    .hero-custom-button {
        --min-font-size: theme(fontSize.xs); /* stylelint-disable-line */
        --max-font-size: theme(fontSize.sm); /* stylelint-disable-line */
        @screen sm {
            font-size: clamp(var(--min-font-size), 0.5625rem + 0.3906vw, var(--max-font-size));
            @apply px-4;
        }
    }
</style>
