interface Coordinates {
    country: string
    lat: number
    lng: number
    municipality: string
    postcodes: string[]
    province: string
    settlement: string // also known as a city
    street: string
    streetnumber: number
}
export async function requestLocation () {
    try {
        const getMyLocation = () => new Promise<GeolocationCoordinates>((resolve, reject) => {
            if (!navigator.geolocation) {
                reject(new Error('Geolocation is not supported by this browser, or access was denied.'))
            } else {
                navigator.permissions.query({ name: 'geolocation' }).then(() => {
                    navigator.geolocation.getCurrentPosition(position => {
                        resolve(position.coords)
                    }, error => {
                        reject(error)
                    })
                })
            }
        })
        const coords = await getMyLocation()
        if (coords.latitude && coords.longitude) {
            return <Coordinates> await $fetch('/api/getAddressByCoords', {
                method: 'post',
                body: {
                    coords: {
                        latitude: coords.latitude,
                        longitude: coords.longitude
                    }
                }
            })
        } else {
            return null
        }
    } catch (error) {
        return null
    }
}
