<template>
    <div v-if="webshopSettings().hasDispatchTypes" :class="currentTabsHeight()">
        <span
            v-if="webshopSettings()?.hasMultipleDispatchTypes"
            class="flex w-fit gap-2 rounded-lg sm:gap-0">
            <button
                v-for="dispatchType in webshopSettings()?.global?.dispatchTypes"
                :key="dispatchType"
                class="w-full cursor-pointer items-center overflow-hidden rounded-b-none rounded-t-lg px-8 py-4 text-lg font-extrabold transition delay-100 ease-in-out"
                :class="[
                    currentHeroTab === dispatchType ? 'rounded-t-lg bg-white text-black hover:bg-white' : 'bg-gray-200 text-gray-600 hover:bg-gray-300',
                    !webshopSettings().global.dispatchTypes.includes(dispatchType) ? '!cursor-not-allowed' : ''
                ]"
                :disabled="!webshopSettings().global.dispatchTypes.includes(dispatchType)"
                @click.prevent="currentHeroTab = dispatchType"
            >
                {{ dispatchType === DispatchTypeOrderBuddy.DELIVER ? $t(DispatchTypeOrderBuddy.DELIVER) : $t(DispatchTypeOrderBuddy.PICK_UP) }}
            </button>
        </span>
        <div
            class="flex min-h-0 flex-col justify-around bg-white p-8 shadow-xl transition-all" :class="{
                'rounded-tr-lg': webshopSettings()?.global?.prizewheelActive,
                'rounded-r-lg': !webshopSettings()?.global?.prizewheelActive
            }">
            <transition name="fade-scale" mode="out-in">
                <HeroDelivery v-if="currentHeroTab === DispatchTypeOrderBuddy.DELIVER" key="delivery" />
                <HeroPickup v-else key="pickup" />
            </transition>
        </div>
        <div v-if="webshopSettings()?.global?.prizewheelActive" class="relative max-w-5xl overflow-hidden rounded-b-lg bg-gray-800">
            <PrizeWheel />
        </div>
    </div>
</template>

<script setup lang="ts">
    import HeroDelivery from '~/components/Hero/HeroDelivery.vue'
    import HeroPickup from '~/components/Hero/HeroPickup.vue'
    import { webshopSettings } from '~/store/webshopSettings'
    import PrizeWheel from '~/components/PrizeWheel.vue'
    import { DispatchTypeOrderBuddy } from '~/types/orderbuddy-types'
    import { checkout } from '~/store/checkout'
    const currentHeroTab = shallowRef(DispatchTypeOrderBuddy.DELIVER)
    onMounted(async () => {
        currentHeroTab.value = checkout().input.dispatchType
    })
    function currentTabsHeight () {
        const shouldShowEmergencyBreakNotification = webshopSettings()?.global?.emergencyBrake?.closedTemporarily || webshopSettings()?.global?.emergencyBrake?.deliveryClosedTemporarily || webshopSettings()?.global?.emergencyBrake?.pickupClosedTemporarily || webshopSettings()?.global?.emergencyBrake?.deliveryMinTimeTemporarily || webshopSettings()?.global?.emergencyBrake?.pickupMinTimeTemporarily
        if (webshopSettings()?.global?.prizewheelActive) {
            if (shouldShowEmergencyBreakNotification) {
                return 'md:min-h-[39rem] lg:min-h-[37rem]'
            } else {
                return ' md:min-h-[35.3rem] lg:min-h-[33.5rem]'
            }
        } else {
            if (shouldShowEmergencyBreakNotification) {
                return 'md:min-h-[21rem]'
            } else {
                return 'md:min-h-[18.4rem]'
            }
        }
    }
</script>
